export default [
  {
    header: 'Dashboard',
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon'
  },
  {
    header: 'Customer',
    title: 'Customer',
    route: 'customer',
    sub: ['customer-add', 'customer-detail'],
    icon: 'UsersIcon'
  },
  {
    header: 'Case',
    title: 'Case',
    route: 'case',
    sub: ['case-add', 'case-detail'],
    icon: 'BookIcon'
  },
  {
    header: 'Knowledge',
    title: 'Knowledge',
    route: 'knowledge',
    sub: ['knowledge-add', 'knowledge-detail'],
    icon: 'SunIcon'
  },
  {
    header: 'Report',
    title: 'Case Detail Report',
    route: 'report',
    icon: 'BookIcon'
  }
]
